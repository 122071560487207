import { atom } from 'recoil'
import { v1 } from 'uuid'

import { localStorageEffect } from '@lib/localstorage'
import { questionGroupType } from 'store/questionGroup/atom'

const deliveryQuestionGroupKey = 'deliveryQuestionGroup'
const defaultQuestionGroup = { id: null, title: '', type: 'public', questions: [] }
const deliveryQuestionGroupAtom = atom<questionGroupType>({
  key: `${deliveryQuestionGroupKey}Raw${v1()}`,
  default: defaultQuestionGroup,
  effects: [localStorageEffect(deliveryQuestionGroupKey)],
})

export { deliveryQuestionGroupAtom as default, defaultQuestionGroup }
