/* eslint-disable react-hooks/exhaustive-deps */
import { useRecoilState } from 'recoil'
import { useCallback, useRef } from 'react'

import questionGroupAtom from 'store/questionGroup'
import useUserChoicesAndWrongsGroupStore, {
  UserChoicesAndWrongs,
} from 'store/userChoicesAndWrongsGroup'
import useViewResultModeStore from 'store/viewResultMode'
import { getQuestionGroupById, getTodayDailyDeliveryQuestions } from '@lib/query/queryFunction'
import { questionIdAndNumber } from 'store/questionGroup/atom'
import deliveryQuestionGroupAtom from 'store/deliveryQuestionGroup'
import useCurrentGroupIdStore from 'store/currentGroupId'
import useCurrentDeliveryGroupIdStore from 'store/currentDeliveryGroupId'
import { DailyQuestionResult } from '@lib/query/mutateFunction'
import useAnswerResultsGroupStore from 'store/answerResultsGroup'

export type AnswerResult = {
  choice: number
  answer: number
  result: boolean
  explanationId?: number
}

const UseExamInform = (isDelivery?: boolean) => {
  const deliveryGroup = useCurrentDeliveryGroupIdStore()
  const normalGroup = useCurrentGroupIdStore()
  const { currentGroupId, setCurrentGroupId } = isDelivery ? deliveryGroup : normalGroup

  const [currentQuestionGroup, setCurrentQuestionGroup] = useRecoilState(
    isDelivery ? deliveryQuestionGroupAtom : questionGroupAtom
  )

  const { viewResultMode } = useViewResultModeStore()

  const { userChoicesAndWrongsGroup, setUserChoicesAndWrongsGroup } =
    useUserChoicesAndWrongsGroupStore()

  const userChoicesAndWrongsGroupRef = useRef(userChoicesAndWrongsGroup)
  userChoicesAndWrongsGroupRef.current = userChoicesAndWrongsGroup

  const { answerResultsGroup, setAnswerResultsGroup } = useAnswerResultsGroupStore()

  const answerResultsGroupRef = useRef(answerResultsGroup)
  answerResultsGroupRef.current = answerResultsGroup

  const setCurrentQuestionGroupByGroupId = useCallback(
    async (groupId: number) => {
      const qg = await getQuestionGroupById(groupId)
      if (qg) setCurrentQuestionGroup(qg)
    },
    [setCurrentQuestionGroup]
  )

  const setCurrentQuestionGroupByCurrentGroupId = useCallback(async () => {
    const qg = await getQuestionGroupById(currentGroupId)
    if (qg) setCurrentQuestionGroup(qg)
  }, [setCurrentQuestionGroup, currentGroupId])

  const setTodayDeliveryQuestionGroup = useCallback(async () => {
    const dailyQuestionsInformation = await getTodayDailyDeliveryQuestions()()
    const dailyQuestionIds: questionIdAndNumber[] = []
    dailyQuestionsInformation?.questions.forEach(({ id }, index) => {
      dailyQuestionIds.push({ id, number: index + 1 })
    })
    setCurrentGroupId(dailyQuestionsInformation.id)
    setCurrentQuestionGroup({
      id: dailyQuestionsInformation.id,
      title: '케어파트너 기출문제 배달',
      type: 'delivery',
      questions: dailyQuestionIds,
    })
  }, [setCurrentQuestionGroup])

  const getUserChoicesAndWrongsGroupByCurrentGroup = useCallback(async () => {
    const group = isDelivery ? userChoicesAndWrongsGroup.delivery : userChoicesAndWrongsGroup.normal
    if (!(currentGroupId in group)) {
      const updatedValue = { [currentGroupId]: {} }
      setUserChoicesAndWrongsGroup((prevState) => ({
        ...prevState,
        [isDelivery ? 'delivery' : 'normal']: { ...group, ...updatedValue },
      }))
    }
    return isDelivery
      ? userChoicesAndWrongsGroupRef.current.delivery[currentGroupId]
      : userChoicesAndWrongsGroupRef.current.normal[currentGroupId]
  }, [currentGroupId, setUserChoicesAndWrongsGroup, userChoicesAndWrongsGroup])

  const updateQuestionInformOfCurrentGroup = useCallback(
    (questionNumber: number, updatedValue: number | number[], valueKey: string) => {
      const group = isDelivery
        ? userChoicesAndWrongsGroup.delivery
        : userChoicesAndWrongsGroup.normal

      if (!(currentGroupId in group)) {
        resetQuestionInformOfCurrentGroup()
      }

      const currentGroupQuestionInformations =
        ((isDelivery
          ? userChoicesAndWrongsGroupRef.current?.delivery[currentGroupId]
          : userChoicesAndWrongsGroupRef.current?.normal[
              currentGroupId
            ]) as UserChoicesAndWrongs) || {}

      const currentQuestioninformation = currentGroupQuestionInformations[questionNumber]
      const updatedResult = {
        [questionNumber]: {
          ...currentQuestioninformation,
          [valueKey]: updatedValue,
          ...(isDelivery && valueKey === 'choice' && { id: getQuestionId(questionNumber) }),
        },
      }

      const updatedQAs = {
        [currentGroupId]: {
          ...currentGroupQuestionInformations,
          ...updatedResult,
        },
      }

      setUserChoicesAndWrongsGroup((prevState) => ({
        ...prevState,
        [isDelivery ? 'delivery' : 'normal']: {
          ...group,
          ...updatedQAs,
        },
      }))
    },
    [currentGroupId, setUserChoicesAndWrongsGroup, userChoicesAndWrongsGroup]
  )

  const resetQuestionInformOfCurrentGroup = useCallback(() => {
    const updatedQAs = {
      [currentGroupId]: {},
    }
    const group = isDelivery ? userChoicesAndWrongsGroup.delivery : userChoicesAndWrongsGroup.normal
    setUserChoicesAndWrongsGroup((prevState) => ({
      ...prevState,
      [isDelivery ? 'delivery' : 'normal']: {
        ...group,
        ...updatedQAs,
      },
    }))
  }, [currentGroupId, setUserChoicesAndWrongsGroup, userChoicesAndWrongsGroup])

  const createAnswerResultOfCurrentGroup = (
    AnswerResults: {
      number: number
      choice: number
      answer: number
      explanationId?: number
      result: boolean
    }[]
  ) => {
    const answerResultsByQuestionNumber: {
      [key: string]: { choice: number; answer: number; result: boolean; explanationId?: number }
    } = {}
    let countWrittenCorrect = 0
    let countPracticalCorrect = 0
    if (AnswerResults.length) {
      AnswerResults.forEach(({ number, choice, answer, result, explanationId }) => {
        answerResultsByQuestionNumber[number] = { choice, answer, result, explanationId }
        if (result) {
          if (number < 36) {
            countWrittenCorrect += 1
          } else {
            countPracticalCorrect += 1
          }
        }
      })
    }
    let passTest = false
    if (countWrittenCorrect >= 21 && countPracticalCorrect >= 27) {
      passTest = true
    }
    const updatedResult = {
      [currentGroupId]: {
        countPracticalCorrect,
        countWrittenCorrect,
        passTest,
        answerResultsByQuestionNumber,
      },
    }

    const group = isDelivery ? answerResultsGroup.delivery : answerResultsGroup.normal

    setAnswerResultsGroup((prevState) => ({
      ...prevState,
      [isDelivery ? 'delivery' : 'normal']: {
        ...group,
        ...updatedResult,
      },
    }))
  }

  const createAnswerResultDailyQuestions = (dailyQuestionResults: DailyQuestionResult[]) => {
    const answerResultsByQuestionNumber: {
      [key: string]: DailyQuestionResult
    } = {}

    if (dailyQuestionResults.length) {
      dailyQuestionResults.forEach((dailyQuestionResult, index) => {
        answerResultsByQuestionNumber[index + 1] = dailyQuestionResult
      })
    }

    const updatedResult = {
      [currentGroupId]: {
        answerResultsByQuestionNumber,
      },
    }

    const group = (isDelivery ? answerResultsGroup.delivery : answerResultsGroup.normal) || {}
    setAnswerResultsGroup((prevState) => ({
      ...prevState,
      [isDelivery ? 'delivery' : 'normal']: {
        ...group,
        ...updatedResult,
      },
    }))
  }

  const getAnswerResultsByCurrentGroup = () => {
    return isDelivery
      ? answerResultsGroupRef.current.delivery[currentGroupId]
      : answerResultsGroupRef.current.normal[currentGroupId]
  }

  const getAnswerResultByQuestionNumber = (questionNumber: number) => {
    const answerResults = getAnswerResultsByCurrentGroup()
    return answerResults?.answerResultsByQuestionNumber[questionNumber]
  }

  const updateQuestionChoiceOfCurrentGroup = useCallback(
    (questionNumber: number, updatedChoice: number) => {
      if (!viewResultMode) {
        updateQuestionInformOfCurrentGroup(questionNumber, updatedChoice, 'choice')
      }
    },
    [updateQuestionInformOfCurrentGroup, viewResultMode]
  )

  const updateQuestionWrongsOfCurrentGroup = (questionNumber: number, updatedWrongs: number[]) => {
    updateQuestionInformOfCurrentGroup(questionNumber, updatedWrongs, 'wrong')
  }

  const updateQuestionIdOfCurrentGroup = (questionNumber: number, updatedId: number) => {
    updateQuestionInformOfCurrentGroup(questionNumber, updatedId, 'id')
  }

  const getQuestionNumber = (questionId: number) => {
    let questionNumber = null
    currentQuestionGroup.questions.forEach(({ id, number }) => {
      if (id === questionId) {
        questionNumber = number
      }
    })
    return questionNumber
  }

  const getQuestionId = (questionNumber: number) => {
    let questionId = null
    currentQuestionGroup.questions.forEach(({ id, number }) => {
      if (number === questionNumber) {
        questionId = id
      }
    })
    return questionId
  }

  return {
    currentGroupId,
    setCurrentGroupId,
    currentQuestionGroup,
    setCurrentQuestionGroup,
    setCurrentQuestionGroupByGroupId,
    setCurrentQuestionGroupByCurrentGroupId,
    setTodayDeliveryQuestionGroup,
    userChoicesAndWrongsGroup:
      (isDelivery ? userChoicesAndWrongsGroup.delivery : userChoicesAndWrongsGroup.normal) || {},
    getUserChoicesAndWrongsGroupByCurrentGroup,
    resetQuestionInformOfCurrentGroup,
    answerResultsGroup:
      (isDelivery ? answerResultsGroup.delivery : answerResultsGroup.normal) || {},
    getAnswerResultsByCurrentGroup,
    getAnswerResultByQuestionNumber,
    createAnswerResultOfCurrentGroup,
    createAnswerResultDailyQuestions,
    updateQuestionChoiceOfCurrentGroup,
    updateQuestionWrongsOfCurrentGroup,
    updateQuestionIdOfCurrentGroup,
    getQuestionNumber,
    getQuestionId,
  }
}

export default UseExamInform
