import { create } from 'zustand'

export interface UserChoicesAndWrongs {
  [key: number]: {
    choice?: number
    wrong?: number[]
    id?: string
  }
}

interface UserChoicesAndWrongsGroupStore {
  userChoicesAndWrongsGroup: {
    normal: UserChoicesAndWrongs
    delivery: UserChoicesAndWrongs
  }
  setUserChoicesAndWrongsGroup: (
    updater:
      | UserChoicesAndWrongsGroupStore['userChoicesAndWrongsGroup']
      | ((
          prev: UserChoicesAndWrongsGroupStore['userChoicesAndWrongsGroup']
        ) => UserChoicesAndWrongsGroupStore['userChoicesAndWrongsGroup'])
  ) => void
}

const useUserChoicesAndWrongsGroupStore = create<UserChoicesAndWrongsGroupStore>((set) => ({
  userChoicesAndWrongsGroup: {
    normal: {},
    delivery: {},
  },
  setUserChoicesAndWrongsGroup: (updater) =>
    set((state) => ({
      userChoicesAndWrongsGroup:
        typeof updater === 'function' ? updater(state.userChoicesAndWrongsGroup) : updater,
    })),
}))

export default useUserChoicesAndWrongsGroupStore
