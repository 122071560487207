import { create } from 'zustand'

import { AnswerResult } from '@hooks/useExamInform'

export interface AnswerResultsAndInformation {
  [key: number]: {
    countPracticalCorrect?: number
    countWrittenCorrect?: number
    passTest?: boolean
    answerResultsByQuestionNumber: {
      [key: string]: AnswerResult
    }
  }
}

interface AnswerResultsGroupStore {
  answerResultsGroup: {
    normal: AnswerResultsAndInformation
    delivery: AnswerResultsAndInformation
  }
  setAnswerResultsGroup: (
    updater:
      | AnswerResultsGroupStore['answerResultsGroup']
      | ((
          prev: AnswerResultsGroupStore['answerResultsGroup']
        ) => AnswerResultsGroupStore['answerResultsGroup'])
  ) => void
}

const useAnswerResultsGroupStore = create<AnswerResultsGroupStore>((set) => ({
  answerResultsGroup: {
    normal: {},
    delivery: {},
  },
  setAnswerResultsGroup: (updater) =>
    set((state) => ({
      answerResultsGroup:
        typeof updater === 'function' ? updater(state.answerResultsGroup) : updater,
    })),
}))

export default useAnswerResultsGroupStore
