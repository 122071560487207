/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useEventLogging } from '@packages/jslib'
import { NextSeo } from 'next-seo'
import * as Sentry from '@sentry/nextjs'
import Image from 'next/image'

import CarepartnerHeader from '@components/CarepartnerHeader'
import QuestionGroupBoxes from '@components/QuestionGroupBoxes'
import { getImageUrl } from '@lib/simpleFn'
import HomeListDeliveryBanner from '@components/HomeListDeliveryBanner'

const Home = () => {
  const { trackPageView } = useEventLogging()

  useEffect(() => {
    trackPageView({
      pageName: 'CBT Exam List',
    })
  }, [])

  const chatChannel = () => {
    try {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_DEVELOPER_KEY)
      }
    } catch (e) {
      Sentry.captureMessage(e as string)
    }
    Kakao.Channel.chat({
      channelPublicId: '_xjwfcb',
    })
  }

  return (
    <>
      <NextSeo
        title="케어파트너 - 요양보호사 자격증 무료 CBT 모의고사"
        description="요양보호사 자격증 CBT 모의고사를 무료로 체험! 요양보호사 자격증 CBT 모의고사를 별도의 비용 없이 무료로 이용해보세요!"
        openGraph={{
          url: process.env.NEXT_PUBLIC_CBT_URL,
        }}
      />
      <div className="overflow-scroll h-[100vh]">
        <CarepartnerHeader />
        <div
          className={
            'relative flex sm:justify-center pb-12 gap-20 pt-24 max-sm:pt-20 max-md:w-auto w-[1280px] m-auto'
          }
        >
          <div className={'relative flex-col max-sm:w-full w-[660px] h-full max-sm:px-4'}>
            <h1 className={'mb-2 text-title-3 font-bold'}>요양보호사 자격증 모의고사</h1>
            <div className={'mt-5'}>
              <HomeListDeliveryBanner />
            </div>
            <QuestionGroupBoxes />
          </div>
          <div className={'fixed bottom-[1vh] right-[2vw]'}>
            <button onClick={chatChannel}>
              <Image
                src={getImageUrl('icons/consult_large_yellow_pc_2X')}
                alt={'카카오톡 채널 채팅하기 버튼'}
                width={128}
                height={58}
                unoptimized
              />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
