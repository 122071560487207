import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const useCurrentGroupIdStore = create(
  persist<{
    currentGroupId: number
    setCurrentGroupId: (currentGroupId: number) => void
  }>(
    (set) => ({
      currentGroupId: 1,
      setCurrentGroupId: (currentGroupId: number) => set({ currentGroupId }),
    }),
    {
      name: 'currentGroupId',
      storage: createJSONStorage(() => localStorage),
    }
  )
)

export default useCurrentGroupIdStore
