import { create } from 'zustand'

const useViewResultModeStore = create<{
  viewResultMode: boolean
  setViewResultMode: (viewResultMode: boolean) => void
}>((set) => ({
  viewResultMode: false,
  setViewResultMode: (viewResultMode) => set({ viewResultMode }),
}))

export default useViewResultModeStore
